import { ChevronRightIcon } from "@heroicons/react/solid";

function BreadCrumbs({ crumbs }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <a
              href="/"
              className="text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Surveys
            </a>
          </div>
        </li>
        {crumbs.map((crumb) => (
          <li key={crumb.crumb}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <a
                href="{crumb.href}"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {crumb.crumb}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
export default BreadCrumbs;
