function InputBox({ value, setValue, id, title }) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="{id}"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {title}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex rounded-md shadow-sm">
          <input
            type="text"
            name="{id}"
            id="{id}"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="p-2 max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-400 rounded-md"
            placeholder="Your Survey Name Here"
          />
        </div>
      </div>
    </div>
  );
}
export default InputBox;
