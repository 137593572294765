import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { classNames } from "../utils";
import { Fragment } from "react";

function DetailSelect({ value, setValue, details, title, id }) {
  return (
    <div
      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
      id={id}
    >
      <label
        htmlFor="about"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {title}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <Listbox value={value} onChange={setValue}>
          {({ open }) => (
            <>
              <div className="relative">
                <div className="inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                  <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                    <div className="relative inline-flex items-center bg-indigo-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      <p className="ml-2.5 text-sm font-medium">
                        {details[value].title}
                      </p>
                    </div>
                    <Listbox.Button className="relative inline-flex items-center bg-indigo-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">
                      <ChevronDownIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </Listbox.Button>
                  </div>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="origin-top-right absolute z-10  mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {Object.keys(details).map((option, index) => (
                      <Listbox.Option
                        key={details[option].title}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-500"
                              : "text-gray-900",
                            "cursor-default select-none relative p-4 text-sm"
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <div className="flex flex-col">
                            <div className="flex justify-between">
                              <p
                                className={
                                  option === value
                                    ? "font-semibold"
                                    : "font-normal"
                                }
                              >
                                {details[option].title}
                              </p>
                              {option === value ? (
                                <span
                                  className={
                                    active ? "text-white" : "text-indigo-500"
                                  }
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </div>
                            <p
                              className={classNames(
                                active ? "text-indigo-200" : "text-gray-500",
                                "mt-2"
                              )}
                            >
                              {details[option].description}
                            </p>
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
}
export default DetailSelect;
