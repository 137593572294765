import { useNavigate } from "raviger";

const VIEWED = 1;
const ATTEMPTED = 2;
const COMPLETED = 3;

function AttemptSurvey({ status, id }) {
  const navigate = useNavigate();

  if (status === VIEWED) {
    return (
      <div className="mt-1/24 relative bg-gray-50 overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-base font-semibold text-gray-600 tracking-wide uppercase">
                    Organization Name
                  </h2>
                  <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                    Hi Vignesh Hari.
                  </p>
                  <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                    Your Organization Requires you to complete this Quiz, Click
                    on the button below to start it. The Quiz can only be
                    attempted once and might be timed so please ensure that you
                    are all set before continuing.
                  </p>
                  <button
                    onClick={() => {
                      navigate(`/s/a/${id}`);
                    }}
                    type="submit"
                    className="mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-gray-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Start Quiz
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  } else if (status === ATTEMPTED) {
    return (
      <div className=" mt-1/12 relative bg-gray-50 overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-4xl">
                Your Really Big Question Here?
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Corporis, cumque deleniti doloribus, ea eos esse, et harum illo
                illum iure laudantium mollitia nostrum odit officia quam ratione
                repellendus saepe. Ab, amet deserunt eius et excepturi, laborum
                magnam minus nobis nostrum odio, odit officia quae quas quidem
                ratione repudiandae vero vitae!
              </p>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <label className="block text-sm font-bold text-gray-700 sm:mt-px sm:pt-2">
                  Answer
                </label>
                <input
                  type="text"
                  className="md:ml-3 p-2 max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-400 rounded-md"
                  placeholder="Enter your answer here"
                />
              </div>
              <button
                onClick={() => {
                  navigate(`/s/c/${id}`);
                }}
                type="submit"
                className="mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-gray-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit Answer
              </button>
            </div>
          </main>
        </div>
      </div>
    );
  } else if (status === COMPLETED) {
    return (
      <div className="mt-1/24 relative bg-gray-50 overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-base font-semibold text-gray-600 tracking-wide uppercase">
                    Organization Name
                  </h2>
                  <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                    Thank you for your time.
                  </p>
                  <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                    You may close this window now.
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
  return <div>Invalid Status</div>;
}

export default AttemptSurvey;
