import { Switch } from "@headlessui/react";
import { classNames } from "../utils";

function InputSwitch({ checked, setChecked, id, title }) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="about"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {title}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <Switch
          id={id}
          checked={checked}
          onChange={(e) => {
            setChecked(e);
          }}
          className={classNames(
            checked ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              checked ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
      </div>
    </div>
  );
}
export default InputSwitch;
