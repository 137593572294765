import { useState } from "react";
import InputSwitch from "../form/InputSwitch";
import InputBox from "../form/InputBox";
import TextArea from "../form/TextArea";
import DetailSelect from "../form/DetailSelect";
import { Container, Draggable } from "react-smooth-dnd";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../utils";
import {
  PencilIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import WarningAlert from "../WarningAlert";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import BreadCrumbs from "../BreadCrumbs";

const QuestionTypes = {
  char: {
    title: "Question",
    description: "Single Line Answers.",
  },
  text: {
    title: "Text",
    description: "Multiple Line Answers.",
  },
  choice: {
    maxOptions: 3,
    title: "Choice",
    description: "Choose One Answer from a list of Answers.",
  },
  multiple: {
    maxOptions: 5,
    title: "Multiple",
    description: "Select more than one correct Answer from a list of Answers.",
  },
};

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

function BuildSurvey(props) {
  const surveyDetails = {};

  function getSurveyDetails(props) {
    surveyDetails.name = "This is a cool Survey";
  }

  const initState = {
    questionType: "choice",
    title: "",
    description: "",
    checkCorrectAnswer: true,
    correctAnswer: "",
    options: [{ id: "1", value: "Option 1", selected: false }],
    option: "",
  };

  const [state, setState] = useState(initState);

  function generateConditionalFlowBuilder() {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Conditional Flows
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <p className="m-1 text-sm text-gray-600">
            This feature is used to route to other questions based on the
            selected choice
          </p>
          <div className="max-w-lg flex rounded-md shadow-sm"></div>
        </div>
      </div>
    );
  }

  function generateSurveyBuilder() {
    if (state.questionType === "char") {
      return (
        <div>
          <InputSwitch
            checked={state.checkCorrectAnswer}
            setChecked={(e) => {
              setState({ ...state, checkCorrectAnswer: e });
            }}
            title="Check For Correct Answer"
            id="checkCorrectAnswer"
          />
          {state.checkCorrectAnswer && (
            <InputBox
              title="Correct Answer"
              id="correctAnswer"
              value={state.correctAnswer}
              setValue={(value) => {
                setState({ ...state, correctAnswer: value });
              }}
            />
          )}
        </div>
      );
    } else if (state.questionType === "text") {
      return (
        <div>
          <InputSwitch
            checked={state.checkCorrectAnswer}
            setChecked={(e) => {
              setState({ ...state, checkCorrectAnswer: e });
            }}
            title="Check For Correct Answer"
            id="checkCorrectAnswer"
          />
          {state.checkCorrectAnswer && (
            <TextArea
              title="Correct Answer"
              id="correctAnswer"
              value={state.correctAnswer}
              setValue={(value) => {
                setState({ ...state, correctAnswer: value });
              }}
            />
          )}
        </div>
      );
    } else if (
      state.questionType === "choice" ||
      state.questionType === "multiple"
    ) {
      return (
        <div>
          <InputSwitch
            checked={state.checkCorrectAnswer}
            setChecked={(e) => {
              setState({ ...state, checkCorrectAnswer: e });
            }}
            title="Check For Correct Answer"
            id="checkCorrectAnswer"
          />
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="{id}"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Options
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <RadioGroup onChange={() => {}}>
                <Container
                  onDrop={(e) =>
                    setState({
                      ...state,
                      options: applyDrag(state.options, e),
                    })
                  }
                >
                  {state.options.map((item, index) => {
                    return (
                      <Draggable key={item.id}>
                        <RadioGroup.Option
                          key={item.value}
                          value={item}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "ring-1 ring-offset-2 ring-indigo-500"
                                : "",
                              "m-2 draggable-item relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
                            )
                          }
                        >
                          <>
                            <div className="flex items-center">
                              <div className="text-sm">
                                <RadioGroup.Label
                                  as="p"
                                  className="font-medium text-gray-900"
                                >
                                  <span
                                    className="column-drag-handle"
                                    style={{
                                      float: "left",
                                      padding: "0 10px",
                                    }}
                                  >
                                    &#x2630;
                                  </span>
                                  <div className="flex">
                                    <div>{item.value}</div>
                                    {item.selected &&
                                      state.checkCorrectAnswer && (
                                        <BadgeCheckIcon
                                          className="ml-1 h-6 w-6 text-green-500"
                                          aria-hidden="true"
                                        />
                                      )}
                                  </div>
                                </RadioGroup.Label>
                              </div>
                            </div>
                            <RadioGroup.Description>
                              {state.checkCorrectAnswer && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    var _data = state.options.slice();
                                    var currentChoice = _data[index].selected;
                                    if (state.questionType === "choice") {
                                      _data.forEach((val, index) => {
                                        _data[index].selected = false;
                                      });
                                    }
                                    _data[index].selected = !currentChoice;
                                    setState({ ...state, options: _data });
                                  }}
                                  className={classNames(
                                    item.selected
                                      ? "bg-red-400 hover:bg-red-600"
                                      : "bg-green-400 hover:bg-green-600",
                                    "inline-flex mr-2  items-center p-1.5 border border-transparent rounded shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  )}
                                >
                                  {item.selected ? (
                                    <XCircleIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <CheckCircleIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              )}

                              <button
                                type="button"
                                className="inline-flex items-center p-1.5 border border-transparent rounded shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                <PencilIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </RadioGroup.Description>
                          </>
                        </RadioGroup.Option>
                      </Draggable>
                    );
                  })}
                </Container>
              </RadioGroup>
              {state.options.length >=
              QuestionTypes[state.questionType].maxOptions ? (
                <WarningAlert
                  title="You cannot add any more options"
                  hideDescription="1"
                />
              ) : (
                <div className="ml-2 mt-4 max-w-lg flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="option"
                    id="option"
                    value={state.option}
                    onChange={(e) => {
                      setState({ ...state, option: e.target.value });
                    }}
                    className="p-4  max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-400 rounded-md"
                    placeholder="Enter New Option"
                  />
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={(e) => {
                      setState({
                        ...state,
                        options: [
                          ...state.options,
                          {
                            id: state.options.length + 1,
                            value: state.option,
                            selected: false,
                          },
                        ],
                        option: "",
                      });
                      e.preventDefault();
                      return false;
                    }}
                  >
                    Add Option
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  getSurveyDetails(props.id);

  return (
    <div className="mt-5 max-w-7xl mx-auto px-4 ">
      <div className="flex flex-col">
        <header className="bg-gray-50 py-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="flex-1 min-w-0">
              <BreadCrumbs crumbs={[{ crumb: "Build", href: "#" }]} />
              <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {surveyDetails.name}
              </h1>
            </div>
          </div>
        </header>
      </div>
      <div>
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={() => {
              return false;
            }}
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Build a new Question.
                  </p>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <InputBox
                    title="Question Title"
                    id="questionName"
                    value={state.title}
                    setValue={(value) => {
                      setState({ ...state, title: value });
                    }}
                  />

                  <TextArea
                    title="Question Description"
                    id="questionDescription"
                    value={state.description}
                    setValue={(value) => {
                      setState({ ...state, description: value });
                    }}
                  />
                </div>
              </div>
            </div>
            <DetailSelect
              value={state.questionType}
              title="Question Type"
              setValue={(value) => {
                setState({ ...state, questionType: value });
              }}
              details={QuestionTypes}
            />
            {generateSurveyBuilder()}
            {generateConditionalFlowBuilder()}
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Complete Survey
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add Next Question
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default BuildSurvey;
