import { useRoutes } from "raviger";
import CreateSurvey from "../survey/CreateSurvey";
import ListSurvey from "../survey/ListSurveys";
import BuildSurvey from "../survey/BuildSurvey";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, PencilAltIcon, XIcon } from "@heroicons/react/outline";
import ViewSurvey from "../survey/ViewSurvey";
import AttemptSurvey from "../survey/AttemptSurvey";

export default function Router() {
  const routes = {
    "/": () => <ListSurvey />,
    "/create": () => <CreateSurvey />,
    "/survey/:id": ({ id }) => <ViewSurvey id={id} />,
    "/survey/:surveyId/question/:questionId": ({ surveyId, questionId }) => (
      <BuildSurvey surveyId={surveyId} questionId={questionId} />
    ),
    "/s/v/:id": ({ id }) => <AttemptSurvey status={1} id={id} />,
    "/s/a/:id": ({ id }) => <AttemptSurvey status={2} id={id} />,
    "/s/c/:id": ({ id }) => <AttemptSurvey status={3} id={id} />,
  };

  let route = useRoutes(routes);
  const attemptPathNameRegEx = new RegExp("/s/");
  const attemptPath = attemptPathNameRegEx.test(window.location.pathname);

  return (
    <div>
      {!attemptPath && (
        <Disclosure as="nav" className="bg-white shadow">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex justify-between h-16">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-0 flex items-center">
                      <PencilAltIcon className="block lg:hidden h-8 w-auto" />
                      <PencilAltIcon className="hidden lg:block h-8 w-auto" />
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                      {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                      <a
                        href="/"
                        className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      >
                        View
                      </a>
                      <a
                        href="/create"
                        className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      >
                        Create
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="pt-2 pb-4 space-y-1">
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  <a
                    href="/"
                    className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  >
                    View
                  </a>
                  <a
                    href="/create"
                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  >
                    Create
                  </a>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      {attemptPath && <div></div>}
      {route}
    </div>
  );
}
