function TextArea({ value, setValue, id, title }) {
  return (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="{id}"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {title}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <textarea
            id="{id}"
            name="{id}"
            rows={3}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="p-2 max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-400 rounded-md"
            placeholder="Write a few sentences about your survey."
          />
        </div>
      </div>
    </div>
  );
}
export default TextArea;
