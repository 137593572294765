import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export default function Pagination({
  currentPage,
  totalPages,
  pageSize,
  totalCount,
  setPage,
}) {
  const changePage = (page) => {
    if (page === currentPage) return;
    setPage(page);
  };

  const nextPage = (e) => {
    changePage(currentPage + 1);
    e.preventDefault();
  };
  const previousPage = (e) => {
    changePage(currentPage - 1);
    e.preventDefault();
  };

  var shownPages = [];
  for (let i = currentPage - 2; i <= currentPage + 2; i++) {
    if (i < 1 || i > totalPages) continue;
    shownPages.push(i);
  }
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        {currentPage !== 1 && (
          <button
            onClick={previousPage}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
        )}
        {currentPage !== totalPages && (
          <button
            onClick={nextPage}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </button>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing
            <span className="ml-1 mr-1 font-medium">
              {(currentPage - 1) * pageSize + 1}
            </span>
            to
            <span className=" ml-1 mr-1 font-medium">
              {currentPage * pageSize}
            </span>
            of
            <span className=" ml-1 font-medium">{totalCount}</span> Questions
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            {currentPage !== 1 && (
              <button
                onClick={previousPage}
                className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {shownPages.map((page) => {
              if (page === currentPage) {
                return (
                  <button
                    onClick={() => {
                      changePage(page);
                    }}
                    key={page}
                    aria-current="page"
                    className="cursor-pointer z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  >
                    {page}
                  </button>
                );
              } else {
                return (
                  <button
                    onClick={() => {
                      changePage(page);
                    }}
                    key={page}
                    className="cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                  >
                    {page}
                  </button>
                );
              }
            })}

            {currentPage !== totalPages && (
              <button
                onClick={nextPage}
                className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
