import BreadCrumbs from "../BreadCrumbs";

import { XCircleIcon, PencilIcon } from "@heroicons/react/outline";
import Pagination from "../Pagination";
import { useState } from "react";
import { useNavigate } from "raviger";

function ViewSurvey({ id }) {
  const navigate = useNavigate();

  const surveyDetails = {};

  const initialState = {
    questions: [
      {
        id: "12",
        name: "Question 1",
      },
      {
        id: "123",
        name: "Question 2",
      },
      {
        id: "1234",
        name: "Question 3",
      },
      {
        id: "1245",
        name: "Question 4",
      },
    ],
    currentPage: 1,
  };

  const [state, setState] = useState(initialState);

  function getSurveyDetails(id) {
    surveyDetails.name = "This is a cool Survey";
  }
  getSurveyDetails();

  return (
    <div className="mt-5 max-w-7xl mx-auto px-4 ">
      <div className="flex flex-col">
        <header className="bg-gray-50 py-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="flex-1 min-w-0">
              <BreadCrumbs crumbs={[{ crumb: "View", href: "#" }]} />
              <div className="flex">
                <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {surveyDetails.name}
                </h1>
                <PencilIcon
                  className=" ml-3 mt-3 h-5 w-5 "
                  aria-hidden="true"
                />
              </div>
              <div>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Survey Details.
                </p>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div>
        <div>
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      width="90%"
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Question
                    </th>
                    <th
                      width="10%"
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {state.questions.map((question) => (
                    <tr key={question.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {question.name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex">
                          <button
                            type="button"
                            className="inline-flex items-center p-1.5 border border-transparent rounded-l-2xl shadow-sm text-white bg-red-400 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <XCircleIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            onClick={() => navigate("/survey/1/question/1")}
                            type="button"
                            className=" inline-flex items-center p-1.5 border border-transparent rounded-r-2xl shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <PencilIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalPages={20}
                currentPage={state.currentPage}
                pageSize={50}
                totalCount={1000}
                setPage={(page) => {
                  setState({ ...state, currentPage: page });
                }}
              />
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end"></div>
        </div>
      </div>
    </div>
  );
}
export default ViewSurvey;
