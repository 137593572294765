import { useState } from "react";
import { useNavigate } from "raviger";
import InputBox from "../form/InputBox";
import TextArea from "../form/TextArea";
import BreadCrumbs from "../BreadCrumbs";
function CreateSurvey() {
  const navigate = useNavigate();
  const initData = { title: "", description: "" };
  const [data, setData] = useState(initData);

  function createSurvey() {
    navigate("/survey/1/question/1/");
    return false;
  }

  return (
    <div className="mt-5 max-w-7xl mx-auto px-4 ">
      <div className="flex flex-col">
        <header className="bg-gray-50 py-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="flex-1 min-w-0">
              <BreadCrumbs crumbs={[{ crumb: "Create", href: "#" }]} />
              <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Create New Survey
              </h1>
            </div>
          </div>
        </header>
      </div>

      <div>
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={createSurvey}
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Complete this form to start building your survey.
                  </p>
                </div>
                <InputBox
                  title="Survey Name"
                  id="surveyName"
                  value={data.title}
                  setValue={(value) => {
                    setData({ ...data, title: value });
                  }}
                />
                <TextArea
                  title={"Survey Description"}
                  id={"surveyDescription"}
                  value={data.description}
                  setValue={(value) => {
                    setData({ ...data, description: value });
                  }}
                />
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateSurvey;
